import { Text, View , StyleSheet,Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef } from 'react'
import axios from 'axios';
import { Input,useToaster, Message, Button ,DatePicker,IconButton ,InputNumber ,Modal ,Checkbox, List, Drawer, MaskedInput,Grid,Col,Panel, Row ,Stack} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar, faHashtag, faMoneyBill, faGauge} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import ExportData from '../../../Components/TableManagement/ExportData';
import CustomText from '../../../Components/CustomText';
import locale from '../../../tr';


const CarServiceScreen = (props) => {


    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [editData,setEditData] = useState({});

    
    function handleModalClose(){
        setModal(false);
    }

    const [modalCarInspection, setModalCarInspection] = useState(false);
    function handleModalCarInspectionClose(){
      setModalCarInspection(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();

    async function getComponents(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
      const api = configdata.API_BASE + '/cardata';
        axios.post(api,{
          "ENV" :configdata.APP_ENV ,  
          "type" : "GET_SERVICE_DATA",
          "ID" : props.data["NO"],
        },{headers}).then((response)=>{
            if(response.data.length > 0){
              setCurrentActive(response.data);
              // fileViewRef.current.set(parseS3(response.data["S3_KEY"]));
            }
            console.log(response.data);
            setData(response.data);
        }).catch((error)=> {
            console.log(error);
        })
    }

    function setCurrentActive(input){
      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          setCurrent(input[i]);
          break;
        }
      }
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }
    const[viewData,setViewData] = useState({});
      
    function chooseANDShow(input){
      setViewData(input);
      setModalFILEOpen(true);
    }

    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }


    function getS3Keys(){
      // if(JSON.parse(props.data["S3 KEYS MAINTENANCE"]).data != null){
      //   return JSON.parse(props.data["S3 KEYS MAINTENANCE"]).data[0];
      // }
      return [];
    }
    const fileViewRef = useRef();
    const FileViewModal = () => {
      return(
        <View>
            <Modal size={'full'}  backdrop= {'static'} keyboard={false} open={modalFILEOpen} onClose={handleCloseFILEModal}>
                    <Modal.Header>
                    <Modal.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"BAKIM BİLGİLERİ"} </Text>
                      </View>} 
                    </Modal.Title>
                    </Modal.Header>
  
                    <Modal.Body>
                    <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button onClick={handleCloseFILEModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
        </View>
      )
    }
    
    const ref =useRef();

    const tableHeads= ["KM","SERVİS BAŞLANGIÇ TARİHİ","SERVİS BİTİŞ TARİHİ","TUTAR","SERVİS","NOTLAR"];
    const listHeads = ["KM","SERVİS BAŞLANGIÇ TARİHİ","SERVİS BİTİŞ TARİHİ","TUTAR","NOTLAR"];


    const headers = [
        {"header" : "KM" , "data" : current["KM"] , "icon" :faGauge },
        {"header" : "SERVİS BAŞLANGIÇ TARİHİ" , "data" : current["SERVİS BAŞLANGIÇ TARİHİ"] , "icon" :faCalendar },
        {"header" : "SERVİS BİTİŞ TARİHİ" , "data" : current["SERVİS BİTİŞ TARİHİ"] , "icon" :faCalendar },
        {"header" : "TUTAR" , "data" : current["TUTAR"] , "icon" :faMoneyBill },
        {"header" : "SERVİS" , "data" : current["SERVİS"] , "icon" :faBuilding },
        {"header" : "NOTLAR" , "data" : current["NOTLAR"] , "icon" :faHashtag },
    ]


    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2',alignItems: 'center', padding: 20}}>
              <Drawer  size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'} backdrop={true} keyboard={false} open={modal} onClose={handleModalClose}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"SEVİS BİLGİLERİ"} </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        {modalType == "New" ? (<NewServiceInfoPanel 
                        type={"NEW"}
                        cardata = {props.data} 
                        data = {editData}
                        reset = {() => {getComponents(); handleModalClose();}}></NewServiceInfoPanel>) 
                        :
                        ( <NewServiceInfoPanel 
                          type={"EDIT"}
                          cardata = {props.data} 
                          data = {editData}
                          reset = {() => {getComponents(); handleModalClose();}} ></NewServiceInfoPanel> )}
                       
                    </Drawer.Body>
                </Drawer>

                <Grid style={{width: '100%'}} fluid>
                  <Row gutter = {20}>
                  <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>

                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200, marginBottom: 20}} 
                            header  = {
                              <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                  <Stack.Item grow={1}>
                                    <IconButton icon={<Icon><FontAwesomeIcon icon={"plus"} /></Icon>}
                                      style={{width: '100%'}}
                                      onClick={() => {
                                      setModal(true);
                                      setModalType("New");}}>
                                      Yeni Sevis Bilgisi
                                    </IconButton>
                                  </Stack.Item>
                                  <ExportData data = {data} tableHead = {tableHeads} tableName={"Bakım_Bilgileri" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                                </Stack> 
                             
                          </View>
                          } >  
                          
                            <CustomText fontWeight= {800} fontSize={14} > {"Bakım Bilgileri" } </CustomText>
                            <ComponentListView hasDoc = {true} current={current} showDetails={showDetailsOfChoosen} listHeads={listHeads} chooseANDShow={chooseANDShow} setchoosen_data={chooseANDEdit}  tableName = {"Servis_" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads}  data={data}></ComponentListView>

                      </Panel>
                      </Col>
                      <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>

                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}} header={ <CustomText style={{fontWeight: 'bold'}}> {"Bakım Bilgileri  - " + props.data["PLAKA"] } </CustomText>}>
                            <List>
                          {headers.map((item) =>
                            {
                            return(<List.Item key={item} index={item}>
                              <CustomListItem item={item} ></CustomListItem>
                            </List.Item>)
                            }
                            )}
                        </List>
                        </Panel>
                      </Col>

                  </Row>
                </Grid>

            {/* <ShowCurrentPanel  data = {data.length == 0 ? defaultData: current} headers={labelHeads} ></ShowCurrentPanel> */}
            <View style={{flexDirection : 'row' , alignItems : 'center'}}>
                <FileViewModal></FileViewModal>
            </View>
        </View>
    )
}


const NewServiceInfoPanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }


    const [activateAddButton,setActiveAddButton] = useState(true);


    // Conbo Box Values
    
    const [maintenanceTypes ,setMaintenanceTypes] = useState([]);
    // SENT VALUES
    const [Km,setKM] = useState(0);
    const [serviceDateStart,setServiceDateStart] = useState(new Date());
    const [servisDateEnd,setServisDateEnd] = useState(new Date());
    const [service,setService] = useState("");
    const [amount,setAmount] = useState(0);
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [S3Key,setS3Key] = useState(undefined);
    const fileUploaderRef= useRef();

    useEffect(() => {
      if(S3Key != undefined){
        console.log(S3Key);
        sendData();
       
      }
    },[S3Key])
    function reset(){
        setKM(0);
        setServiceDateStart(new Date());
        setServisDateEnd(new Date());
        setService("");
        setAmount(0);
        setNote("");
        setIS_ACTIVE(true);
        setS3Key(undefined);
    }

    function getEditData(){
        setKM(props.data["KM"]);
        setServiceDateStart(props.data["SERVİS BAŞLANGIÇ TARİHİ"]);
        setServisDateEnd(props.data["SERVİS BİTİŞ TARİHİ"]);
        setService(props.data["SERVİS"]);
        setAmount(props.data["TUTAR"]);
        setNote(props.data["NOTLAR"]);
        setIS_ACTIVE(props.data["IS_ACTIVE"] == 1 ? true : false);

  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }


    function sendAndLoad(){
      fileUploaderRef.current.load();
   }

   const {fetchIdToken} = useUserToken();
   async function sendData(){
    const idToken = await fetchIdToken();
    const headers = {
      "Authorization" : `Bearer ${idToken}`  
      
    }
    const api = configdata.API_BASE + '/cardata';
    let tempTutar = amount.replace("₺","").replace(".","").replace(",",".");

    let newKm = Km != 0 ?  Km.replace(".","").replace(",",".").replace(" KM", ""): Km;

    const body = {
        "ENV" :configdata.APP_ENV ,  
        "type": props.type == 'EDIT' ? "UPDATE_SERVICE" : "NEW_SERVICE_DOC",
        "KM" : newKm,
        "SERVICE_START_DATE" : serviceDateStart,
        "SERVICE_END_DATE" : servisDateEnd,
        "AMOUNT" : tempTutar,
        "SERVICE" : service,
        "NOTES" :note,
        "CAR_ID" : props.cardata["NO"],
        "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
        "ID" : props.data["NO"],
      }

    axios.post(api,body,{headers}
    ).then((response) => {
      console.log(response);
      reset();
      props.reset();
      if(response.data["errorMessage"] != null){
          messageToggle("Servis Bilgileri eklenemedi , Bağlantınızı kontrol edin", "error")
      }else{

        if(response.data.split("_")[0] == "SUCCESS"){
          messageToggle("Servis Bilgileri Başarıyla Eklendi", "success")
          fileUploaderRef.current.load(response.data.split("_")[1]);
        }
        else{
          messageToggle("Servis Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
        
        }
      }
      
    })
    .catch((error) => {
      console.log(error);
      messageToggle("Servis Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
    });
    }

    useEffect(() => {
     if(props.type == "EDIT"){getEditData()}
    },[])


    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

    const tlMask = createNumberMask({
        prefix: '₺',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 7, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
        });
   
    
    return(
          <View style= {{flexDirection: 'column',width: '100%'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
                        <View style={styles.form} >
                          <Group header = {"Kilometre Bilgisi"}>
                            <InputNumber value={Km} style={styles.standard} onChange={ (value) => {setKM(value);}} name= {"KM"} /> 
                          </Group>
                        
                          <Group header = {"Servis"}>
                            <Input value={service} style={styles.standard}  onChange={ (value) => {setService(value);}} name= {"service"} /> 
                          </Group>
                  
                          {/* Başlangıç Tarihi */}
                          <Group header = {"Başlangıç Tarihi"}>
                            <DatePicker  value={serviceDateStart} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                                  {setServiceDateStart(value);}}
                                  oneTap format="dd-MM-yyyy"
                                  locale={locale}
                              ></DatePicker>
                          </Group>
                          <Group header = {"Bitiş Tarihi"}>
                            <DatePicker  value={servisDateEnd} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                                    {setServisDateEnd(value);}}
                                    oneTap format="dd-MM-yyyy"
                                    locale={locale}
                                ></DatePicker>
                        </Group>

                            {/* Tutar */}
                          <Group  header = {"Tutar"}>
                            <MaskedInput mask={tlMask} value={amount} style={styles.standard} min={0} onChange={ (value) => {setAmount(value);}} name= {"tutar"} /> 
                          </Group>
                          {
                            props.type == "EDIT" && (
                            <Group header = {"Durum"}>
                                <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                              </Group>
                            )
                          }
                          <Group header = {"Notlar"}>
                            <Input as="textarea"  value={note} style={styles.standard}  onChange={ (value) => {setNote(value);}} name= {"notlar"} />
                          </Group>
                  
                        <Group header = {"Dosya"}>
                          <FileLoader reset={props.reset} ref = {fileUploaderRef} fileCategory = {"SERVICE"} carID = {props.cardata["NO"]}></FileLoader>
                        </Group>
                      </View>
                <View style={{height: 15}} ></View>
                <Button
              disabled = {!activateAddButton}
              style={styles.buttonStyle}
              onClick={() => { sendData();}}
            > <CustomText fontWeight = {800}  fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>
        </View>
)}


const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight: 5 }}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);

const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  form: {
    width: '100%',
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  standard: { flex: 1 , maxWidth: 300, minWidth: 150,width: '100%'},
  input: {
    flex: 1,
  },
});



export default CarServiceScreen;