import React, { useEffect, useState ,useImperativeHandle, useRef } from 'react';
import { StyleSheet, Dimensions, View ,FlatList,Text, ScrollView} from 'react-native';
import { FileUploader } from "react-drag-drop-files";
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import PDF from '../PDF';
import StandardButton from '../StandardButton';
import { Modal } from 'react-native-paper';
import axios from 'axios';
import { forwardRef } from 'react';
import configdata from '../../config/config.json';
import { Button, IconButton, Stack } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Image from 'react-bootstrap/Image';
import ImageToPdfExporter from './ImageToPdfExporter';
// Amplify.configure(awsconfig);
Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});

const ImageViewer = (props,ref) => {

    const [url,setURL] = useState("");
    const [width, setWidth] = useState(400 );
    const [height, setHeight] = useState(400 * 1.41421);
    const [aspectRatio,SetAspectRatio] = useState(1);
    const [refresh,setRefresh] = useState(false);
    const [s3KEY,setS3Key] = useState(undefined);
    
    useImperativeHandle(ref, () => ({
    refresh: () => {
        setRefresh(!refresh);
    },
    set: (input) => {
        
        if(input != null){
          
            setS3Key(parseS3Response(input));
        }
      
       
    }
}));

function parseS3Response(response) {
    const { data, status } = response;
    if (status !== 200) {
        throw new Error('Failed to fetch the file');
    }else{
       let dataNew =  JSON.parse(data[0]["S3 KEY LICENCES"])
        let final = sortLicenceKeysByID(dataNew.data);

       return final[0].S3_KEY;
    }

}

    function sortLicenceKeysByID(data) {
        let temp = [...data];
        temp.sort((a, b) => {
            return a["ID"] - b["ID"];
        });
        return temp;
    }


    useEffect(()=>{

        getURL(s3KEY);
        
    },[s3KEY])

    useEffect(()=>{
        if(props.s3KEY != null && props.s3KEY != undefined && typeof(props.s3KEY) == 'string'){
            setS3Key(props.s3KEY);
        }
    },[])

    const getURL = async (input) => {
        if(input != null && input != undefined && s3KEY != undefined){
            await Storage.get(input,{ 
                expires: 120,
                level: 'public/'
              /*   identityId: '2cd3970d-b634-4bf1-b27a-86fc58ff3fa7' */
            }).then
            (data => {
                setURL(data);
                // console.log("----- url name " + data);

                if(s3KEY.split('.')[1].includes("png") || s3KEY.split('.')[1].includes("jpg") || s3KEY.split('.')[1].includes("jpeg")){
                    Image.getSize(data, (width,height) => {
                        if(props.inline){
                            setWidth(width); 
                            setHeight(height);
                        }else{
                            setWidth(400); 
                            setHeight(400 * (width / height));
                        }
                    });
                }
                
            }).catch(
                err => {
                    console.log("storage error " + err)
                }
            );
        }
     
    } 

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename.split("/")[filename.split("/").length -1 ] || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
      }
      
      // usage
      async function download(item) {
        const result = await Storage.get(s3KEY, { download: true });
        downloadBlob(result.Body, s3KEY)
      }





    const [rotate,setRotate] = useState(0);


    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {s3KEY != undefined && 
            <Stack wrap spacing={10}>
                <IconButton onClick={download} icon={<Icon><FontAwesomeIcon icon={"file"}></FontAwesomeIcon></Icon>}> İndir </IconButton>
                <IconButton onClick={() => setRotate(`${(parseInt(rotate) + 90) % 360}deg`)} icon={<Icon><FontAwesomeIcon icon={faRotate}></FontAwesomeIcon></Icon>}>Döndür</IconButton>
                {props.PDFConvertable == true && <ImageToPdfExporter image={url} s3KEY={s3KEY}></ImageToPdfExporter>}
            </Stack>
            }
            <View style={{ height: 10 }} />
            {(s3KEY && typeof(s3KEY) == 'string') && (
                <View style={{ flex :1 , height: 400}} >
                    {["png", "jpg", "jpeg"].includes(s3KEY.split('.').pop()) && 
                        <Image
                            style={{maxHeight: 400}}
                            src={url}
                            
                        />
                    }
                </View>
            )}
        </View>

    )
}


const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.10,
        shadowRadius: 4,
        elevation: 5
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height:'100%',
        margin: 25,
    },

    listItem:{
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
});

export default forwardRef(ImageViewer) ;